import { render, staticRenderFns } from "./Eventspage.vue?vue&type=template&id=67ea7cd2&scoped=true&"
import script from "./Eventspage.vue?vue&type=script&lang=js&"
export * from "./Eventspage.vue?vue&type=script&lang=js&"
import style0 from "./Eventspage.vue?vue&type=style&index=0&id=67ea7cd2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67ea7cd2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VIcon,VImg})
