<template>
    <div class="center">

        <div class="center">
            <h1>{{ newscontent.title }}</h1>
            <div style="margin:auto; padding:10px 0;"><v-icon>mdi-calendar-month</v-icon> {{ newscontent.posted_date }}</div>
            
            <div>
                <v-img
                    class="news-image"
                    :src="newscontent.src"
                ></v-img>
                <p class="news-description" v-html="newscontent.description">
                </p>
            </div>
                
        </div>
    </div>
</template>

<script>
import Data from '../data'

export default {
    data() {
        return {
            news: Data.news
        }
    },
    created() {
        this.newscontent=this.news[this.$route.params.id-1]
    },
}
</script>

<style scoped>
.news-description{
    color:#2D2C2C;
    text-align: justify;
    text-justify: inter-word;
}
.news-image{
    width:100%;
    height: auto;
    margin-bottom:30px;
}
</style>